<template>
  <div>
    <!-- 邮箱订阅 -->
    <div class="banner" id="investor_banner1" :style="{backgroundImage:('url('+banner+')')}">
      <!-- <img
        src="../../assets/images/investors/investor_banner01.jpg"
        :alt="$t('indexnav.Investor Relations')"
      /> -->
      <span
        >{{ $t("indexnav.Investor Relations")
        }}<i
          :style="{
            display: this.$store.state.nowlanguage === 'en' ? 'none' : '',
          }"
          >Investor Relations</i
        ></span
      >
    </div>

    <section class="ab-item1">
      <!-- <a href="/">{{ $t("indexnav.Home") }}</a> >
      <a href="/ir/default.html">{{ $t("indexnav.Investor Relations") }}</a> >
      {{ $t("indexnav.Email Subscription") }} -->
      <bread-crumb />
    </section>
    <section>
      <div id="iframeContainer"></div>
    </section>
    <!-- 表单验证 -->
    <div v-if="false" style="margin: 30px 0">
      <section>
        <!-- 标语 -->
        <h5>{{ $t("email.title") }}</h5>
        <div class="Form">
          <!--  ,rules:this.$store.state.nowlanguage === 'en',trrules:this.$store.state.nowlanguage === 'tr' -->
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForms"
            label-width="100px"
            class="demo-ruleForm"
            label-position="left"
          >
            <!-- 姓名 -->
            <el-form-item :label="$t('email.From.姓名')" prop="name">
              <el-input v-model="ruleForm.name" size="mini"></el-input>
            </el-form-item>
            <!-- 电邮地址 -->
            <el-form-item :label="$t('email.From.电邮地址')" prop="email">
              <el-input v-model="ruleForm.email" size="mini"></el-input>
            </el-form-item>
            <!-- 公司 -->
            <el-form-item :label="$t('email.From.公司')">
              <el-input size="mini" v-model="ruleForm.company"></el-input>
            </el-form-item>
            <!-- 职位 -->
            <el-form-item :label="$t('email.From.职位')" prop="delivery">
              <el-input size="mini" v-model="ruleForm.position"></el-input>
            </el-form-item>
            <!-- 地区 -->
            <el-form-item :label="$t('email.From.地区')" prop="address">
              <el-select
                size="mini"
                style="width: 100%"
                v-model="ruleForm.address"
                :placeholder="$t('email.From.请选择')"
              >
                <div
                  v-for="(item, index) in reallyRegionList"
                  :key="index + 'key'"
                >
                  <!-- <div>{{ item.name }}</div> -->
                  <el-option :label="item.name" :value="item.name"> </el-option>
                </div>
              </el-select>
            </el-form-item>
            <!-- 联络电话 -->
            <el-form-item
              :label="$t('email.From.联络电话')"
              prop="resource"
              id="Form_pohone"
            >
              +<el-input
                size="mini"
                style="width: 8%"
                v-model="phoneHeade"
              ></el-input>
              <el-input
                size="mini"
                style="flex: 1; margin-left: 4px"
                v-model="phone"
              ></el-input>
              <span style="color: #9a9a9a">{{ $t("email.From.内线") }}</span
              ><el-input
                size="mini"
                style="width: 8%"
                v-model="ruleForm.innerPhone"
              ></el-input>
            </el-form-item>
            <!-- 语言 -->
            <el-form-item
              :label="$t('email.From.语言')"
              prop="language"
              required
            >
              <el-radio-group v-model="ruleForm.language">
                <el-radio :label="3">{{ $t("email.From.英文") }}</el-radio>
                <el-radio :label="6">{{ $t("email.From.中文") }}</el-radio>
                <el-radio :label="9">{{ $t("email.From.中文&英文") }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item prop="captcha" required>
              <p>
                <img
                  @click="getVerificationCode"
                  :src="yanzhenma"
                  style="width: 200px"
                />
              </p>
              <el-input
                size="mini"
                style="width: 20%"
                v-model="ruleForm.captcha"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForms')">{{
                $t("email.From.提交")
              }}</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-dialog :visible.sync="dialogVisible" width="30%">
          <div style="text-align: center">
            <img
              src="../../assets/images/investors/email/20291e66add3c82322dd0d51bb5f61a.jpg"
              style="width: 200px; height: 200px"
            />
            <p>感谢提交.关注公众号可以获取一手信息哦~</p>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="okBtn"
              style="background-color: #2b426a; border-color: #2b426a"
              >确 定</el-button
            >
          </span>
        </el-dialog>
      </section>
    </div>
  </div>
</template>

<script>
import { postAction,getAction } from "@/api/manage";
import { ifarmeMixin } from "../mixin/ifarmeMixin";
import { PageType } from "@/utils/constant";

export default {
   mixins: [
    ifarmeMixin
  ],
  data() {
    return {
      izh: "https://alertform.website.wisdomir.com/main/index.php?id=485&lang=sc",
      itr: "https://alertform.website.wisdomir.com/main/index.php?id=485&lang=tc",
      ien: "https://alertform.website.wisdomir.com/main/index.php?id=485&lang=en",
      dialogVisible: false,
      banner:'',
      yanzhenma: "",
      // 电话号码前的数子
      phoneHeade: "",
      // 电话
      phone: "",
      // 当前语言
      nowLanguage: "",
      // 表单验证数据
      reallyRule: {},
      // 地址数据
      reallyRegionList: [],
      // emailSubscription
      ruleForm: {
        // name: "", // 姓名
        // email: "", // 电邮
        // company: "", // 公司
        // position: "", // 职位
        // address: "", // 地区
        // pohone: "", // 电话
        // language: "", // 语言
        // resource: "",
        // desc: "",
        // captcha: "", //验证码

        Rules: this.$store.state.nowanguage,
      },

      // 中文表单验证
      rules: {
        // 姓名验证
        name: [
          { required: true, message: "请输入您的姓名", trigger: "change" },
          {
            min: 2,
            max: 5,
            message: "长度在 2 到 5 个字符",
            trigger: "change",
          },
        ],
        // 邮箱验证
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "change" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: "change",
          },
        ],
        // 地区验证
        address: [
          { required: true, message: "请选择您的地区", trigger: "change" },
        ],
        // 语言验证
        language: [
          { required: true, message: "请选择您的语言", trigger: "change" },
        ],
        // 验证码
        captcha: [
          { required: true, message: "请填写您的验证码 ", trigger: "change" },
        ],
      },
      // 英文表单验证
      enrules: {
        // 姓名验证
        name: [
          {
            required: true,
            message: "Please enter your name",
            trigger: "change",
          },
          {
            min: 2,
            max: 5,
            message: "The length is 2 to 5 characters",
            trigger: "change",
          },
        ],
        // 邮箱验证
        email: [
          {
            required: true,
            message: "Please enter your e-mail address",
            trigger: "change",
          },
          {
            type: "email",
            message: "Please enter the correct email address",
            trigger: ["blur", "change"],
          },
        ],
        // 地区验证
        address: [
          {
            required: true,
            message: "Please select your region",
            trigger: "change",
          },
        ],
        // 语言验证
        language: [
          {
            required: true,
            message: "Please select your language",
            trigger: "change",
          },
        ],
        // 验证码
        captcha: [
          {
            required: true,
            message: "Please fill in your verification code",
            trigger: "change",
          },
        ],
      },
      // 繁体表单验证
      trrules: {
        // 姓名验证
        name: [
          { required: true, message: "請輸入您的姓名", trigger: "change" },
          { min: 2, max: 5, message: "長度在 2 到 5 個字符", trigger: "change" },
        ],
        // 邮箱验证
        email: [
          { required: true, message: "請輸入郵箱地址", trigger: "change" },
          {
            type: "email",
            message: "請輸入正確的郵箱地址",
            trigger: "change",
          },
        ],
        // 地区验证
        address: [
          { required: true, message: "請選擇您的地區", trigger: "change" },
        ],
        // 语言验证
        language: [
          { required: true, message: "請選擇您的語言", trigger: "change" },
        ],
        // 验证码
        captcha: [
          { required: true, message: "請填寫您的驗證碼", trigger: "change" },
        ],
      },
      // 选择地区
      zhRegionList: [
        {
          name: "澳大利亚",
        },
        {
          name: "中国",
        },
        {
          name: "香港",
        },
        {
          name: "新加坡",
        },
        {
          name: "英国",
        },
        {
          name: "美国",
        },
        {
          name: "其他",
        },
      ],
      trRegionList: [
        {
          name: "澳大利亞",
        },
        {
          name: "中國",
        },
        {
          name: "香港",
        },
        {
          name: "新加坡",
        },
        {
          name: "英國",
        },
        {
          name: "美國",
        },
        {
          name: "其他",
        },
      ],
      enRegionList: [
        {
          name: "Australia",
        },
        {
          name: "China",
        },
        {
          name: "HongKong",
        },
        {
          name: "Singapore",
        },
        {
          name: "United Kingdom",
        },
        {
          name: "United States",
        },
        {
          name: "Others",
        },
      ],
    };
  },
  created() {
    this.reallyRule={}
    this.ChangeRule();
    console.log(this.reallyRule);
     // 初始化banner
    let that = this;
    getAction("/jeecg-system/common/frontend/advertise/active_list", {
      typeCode: PageType.YOUJIANDINGYUE,
    }).then((res) => {
      if (
        res.result.records instanceof Array &&
        res.result.records.length > 0
      ) {
         if (res.result.records[0].isMultipatLanguge!=='0') {
          if (this.$store.state.nowlanguage == "zh")
            that.banner = res.result.records[0].sampleImg;
          if (this.$store.state.nowlanguage == "tr")
            that.banner = res.result.records[0].tranditionalImg;
          if (this.$store.state.nowlanguage == "en")
            that.banner = res.result.records[0].enImg;;
        }else{
        that.banner = res.result.records[0].sampleImg
        }
      }
    });
  },
  methods: {
    changeRadio(val) {
      console.log(val);
    },
    ChangeRule() {
      this.nowLanguage = this.$store.state.nowlanguage;
      if (this.nowLanguage == "zh") {
        this.reallyRule = this.rules;
        this.reallyRegionList = this.zhRegionList;
      }
      if (this.nowLanguage == "en") {
        this.reallyRule = this.enrules;
        this.reallyRegionList = this.enRegionList;
      }
      if (this.nowLanguage == "tr") {
        this.reallyRule = this.trrules;
        this.reallyRegionList = this.trRegionList;
      }
      this.getVerificationCode();
    },
    getVerificationCode() {
      this.yanzhenma =
        process.env.VUE_APP_BASE_API +
        "/jeecg-system/frontend/emailSubscription/get_captcha?time=" +
        Math.random();
    },
    // 提交成功弹出
    open() {
      this.dialogVisible = true;
      // let Img = require("../../assets/images/investors/email/20291e66add3c82322dd0d51bb5f61a.jpg");
      // this.$alert(
      //   `<img id='img_erweima' src='' /><p style='text-align: center;'>感谢提交.关注公众号可以获取一手信息哦~</p>`,
      //   "",
      //   {
      //     dangerouslyUseHTMLString: true,
      //     closeOnPressEscape: true,
      //     closeOnClickModal: true,
      //   }
      // );
      // let img_erweima = document.getElementById("img_erweima");
      // img_erweima.src = Img;
      // this.$forceUpdate();
    },
    okBtn() {
      this.dialogVisible = false;
      this.getVerificationCode();
      this.ruleForm = {
        name: "", // 姓名
        email: "", // 电邮
        company: "", // 公司
        position: "", // 职位
        address: "", // 地区
        pohone: "", // 电话
        language: "", // 语言
        resource: "",
        desc: "",
        captcha: "", //验证码
      };
      this.$refs["ruleForms"].resetFields();
    },
    // 提交按钮
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.phone && this.phoneHeade == "") {
            this.ruleForm.telephoe = this.phone;
          } else if (this.phone && this.phoneHeade !== "") {
            this.ruleForm.telephoe = "+" + this.phoneHeade + "-" + this.phone;
          }
          if (this.ruleForm.language == "3") {
            this.ruleForm.language = "en";
          }
          if (this.ruleForm.language == "6") {
            this.ruleForm.language = "chinese";
          }
          if (this.ruleForm.language == "9") {
            this.ruleForm.language = '"en_chinese"';
          }
          postAction(
            "/jeecg-system/frontend/emailSubscription/add",
            this.ruleForm
          ).then((res) => {
            if (res.success) {
              this.$message({
                message: res.message,
                type: "success",
              });
              this.open();
            } else {
              this.$message({
                message: res.message,
                type: "error",
              });
            }
          });
        } else {
          console.log(this.reallyRule);
        }
      });
    },
  },
  watch: {
    // 监听当前vuex中的当前语言变化 改变本页面data中的当前语言
    now(newval, oldval) {
      // 根据当前语言改变表单验证提示
      // if (newval === "en") {
      //   this.Rules = this.enrules;
      // } else if (newval === "zh") {
      //   this.Rules = this.rules;
      // } else {
      //   this.Rules = this.trrules;
      // }
      // 改变当前语言
      this.nowLanguage = newval;
    },
  },
  computed: {
    // 返回vuex当前语言
    now() {
      return this.$store.state.nowlanguage;
    },
  },
    mounted () {
       if (this.$store.state.nowlanguage == "zh") {
      this.setIframe(this.izh);
    }
    if (this.$store.state.nowlanguage == "tr") {
      this.setIframe(this.itr);
    }
    if (this.$store.state.nowlanguage == "en") {
      this.setIframe(this.ien);
    }
  }
};
</script>

<style scoped lang='less'>
/deep/ .el-message-box__message {
  img {
    width: 200px;
    height: 200px;
  }
}
#Form_pohone /deep/ .el-form-item__content {
  display: flex;
}
/deep/ .el-radio__inner {
  padding: 15px;
  background-color: #bbbbbb;
}
/deep/ .el-radio__input.is-checked .el-radio__inner::after {
  content: "";
  width: 0px;
  height: 0px;
  border-top: transparent;
  border-right: transparent;
  text-align: center;
  display: block;
  position: absolute;
  top: 2px;
  left: 1px;
  vertical-align: middle;
  transform: rotate(-45deg);
  border-radius: 0px;
  background: none;
}
/deep/ form > div {
  display: flex;
  .el-form-item__label {
    width: 150px !important;
    display: flex;
    flex-direction: row-reverse;
    justify-content: start;
    font-size: 13px;
    color: #bbb !important;
  }
  .el-form-item__content {
    margin-left: 0px !important;
    width: 50%;
  }
  .el-input__inner {
    border-radius: 0px !important;
    border: 1px solid #bbbbbb;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #bbb !important;
  }
  .el-radio__input.is-checked .el-radio__inner {
    background-color: #bbb;
    position: relative;
    border: 1px solid #fff;
  }
  .el-radio__inner::after {
    display: none;
    position: absolute;
    font: 13px/1 "Open Sans", sans-serif;
    left: 17px !important;
    top: 6px !important;
    content: "\02143" !important;
    transform: rotate(40deg) !important;
  }
  .el-button--primary {
    color: #fff;
    background-color: #18325f;
    border-color: #fff;
  }
  button {
    padding: 6px 10px;
    border-radius: 0px;
    background-color: #18325f;
  }
  /* 选项选中效果 */
  .el-radio__inner:hover {
    border: 1px solid #fff;
    opacity: 0.9;
  }
  .el-radio__inner::after {
    width: 0px !important;
  }
  .el-radio__label {
    color: #bbb;
  }
}

header + a img {
  max-width: 100%;
  width: 100%;
}

h5 {
  margin: 0em auto 0 auto;
  width: 100%;
  max-width: 1200px;
  background: #18325f;
  color: #fff;
  font-size: 1rem;
  padding: 5px 10px;
  font-size: 12px;
}
.Form {
  margin: 0em auto 0 auto;
  width: 100%;
  max-width: 1200px;
  margin-top: 10px;
  .Form_name {
    margin-top: 10px;
    display: flex;
    span {
      width: 150px;
      display: flex;
      align-items: center;
    }
  }
}
.banner {
  position: relative;
}
.banner img {
  width: 100%;
  max-width: 100vw;
  display: block;
}

section,
figure {
  margin: 1.5em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

.ab-item1 {
  padding: 0 0 1.5em 0;
  border-bottom: 1px solid #d2d2d2;
  font-size: 1.5rem;
  color: #595757;
}

figure div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2em;
}

figure div a {
  display: flex;
  justify-items: center;
  width: 32%;
  margin-right: 2%;
  background: #2e466f;
  border-radius: 10px;
  line-height: 3em;
  font-size: 1.4em;
  color: #fff;
  justify-content: center;
  margin-bottom: 1.5em;
}

figure div a:nth-child(3),
figure div a:nth-child(6),
figure div a:nth-child(9),
figure div a:nth-child(12) {
  margin-right: 0;
}
figure div a:hover {
  color: red;
}

@media screen and (max-width: 1200px) {
  .ab-item1 {
    font-size: 1.2em;
    padding: 0 1.5em 1.5em 1.5em;
  }

  figure {
    padding: 0 1.5em 1.5em 0.7em;
  }
}

@media screen and (max-width: 768px) {
  figure div a {
    width: 100%;
    margin-right: 0;
  }
}
</style>
